import { render, staticRenderFns } from "./DemoCreationMetrics.vue?vue&type=template&id=37e5ae1d&"
import script from "./DemoCreationMetrics.vue?vue&type=script&lang=js&"
export * from "./DemoCreationMetrics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports