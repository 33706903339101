<template>
  <div>
    <div>
        Threshold: <input type="text" v-model="threshold" />
        <div>
            <span v-for="eventName in Object.keys(eventCountsAfterFiltering)" :key="eventName">{{ eventName }}: {{eventCountsAfterFiltering[eventName]}}  </span>
        </div>
    </div>
      <div>
          <div class="w-100">
              <div></div>
                <!-- <button class="btn btn-primary" @click="calculateChartData">Reload Chart</button> -->
                <download-csv class="ms-auto" :json-data="niceData">
                    <button class="btn btn-primary">Download as CSV</button>
                </download-csv>
            </div>
          <!-- <line-chart :chartData="chartData" /> -->
      </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Axios from "axios"

export default {
  components: {  },
    data() {
        return {
            threshold: 0.5,
            metrics: null,
            eventCountsAfterFiltering: {},
            niceData: null,
            chartDatasets: [],
            timeIndexes: [],
            colourMappings: {
                'KICK': { backgroundColor: 'red' },
                'CONTACT': { backgroundColor: 'green' },
                'NO_EVENT': { backgroundColor: 'blue' },
            }
        }
    },
    async mounted() {
        try { 
            const { data: metrics } = await Axios.get(this.selectedTask.metrics)
            this.metrics = metrics;
            // this.calculateChartData();
        } catch (e) {
            console.error("Unable to get metrics");
            console.error(e);
        }
    },
    computed: {
        ...mapGetters([ 'selectedTask']),
    },
    methods: {
        // calculateChartData() {
        //     const metrics = this.metrics;
        //     if (!metrics) return {};

        //     let eventsDatasets = {};
        //     let eventCountsAfterFilteringTemp = {};
        //     let filteringToggles = {};
        //     this.niceData = [];

        //     let times = []
        //     for (const metric of metrics) {
        //         let timeIndex = metric.timeindex;
        //         if (!timeIndex && metric.frameId) timeIndex = /-out-(\d+)\.jpg/.exec(metric.frameId)[1]
                    
        //         let row = { timeIndex, threshold: this.threshold };
        //         times.push(timeIndex);

        //         for (const event of metric.event_predictions) {
        //             eventsDatasets[event.tagName] = eventsDatasets[event.tagName] || [];
        //             eventCountsAfterFilteringTemp[event.tagName] = eventCountsAfterFilteringTemp[event.tagName] || 0;
        //             filteringToggles[event.tagName] = filteringToggles[event.tagName] || false;
                
        //             eventsDatasets[event.tagName].push({
        //                 x: timeIndex,
        //                 y: event.probability,
        //             });

        //             if (event.probability > this.threshold) {
        //                 // We have gone above threshold 
        //                 if (!filteringToggles[event.tagName]) {
        //                     filteringToggles[event.tagName] = true
        //                     eventCountsAfterFilteringTemp[event.tagName]++;
        //                 }
        //             } else {
        //                 // We have dropped below threshold
        //                 if (filteringToggles[event.tagName]) {
        //                     filteringToggles[event.tagName] = false;
        //                 }
        //             }

        //             row[event.tagName] = event.probability
        //             row[event.tagName + "_count"] = eventCountsAfterFilteringTemp[event.tagName]
        //             row[event.tagName + "_detected"] = event.probability > this.threshold
        //         }

        //         this.niceData.push(row);
        //     }

        //     this.timeIndexes = times;
        //     this.eventCountsAfterFiltering = eventCountsAfterFilteringTemp;

        //     let datasets = Object.keys(eventsDatasets).map(eventName => ({
        //         label: eventName,
        //         data: eventsDatasets[eventName],
        //         ...this.colourMappings[eventName]
        //     }));
            
        //     this.chartDatasets = datasets;
        // }
    }
}
</script>

<style>

</style>