<template>
    <main class="h-100" v-if="selectedTask">
      <div class="container">
        <div class="mt-3">
          <div @click="restartTask" class="btn btn-warning">Restart Task</div>
        </div>

        <div v-if="lastUpdate">Last refresh: {{ lastUpdate.toISOString() }}</div>
        <div v-if="selectedTask.updatedAt">Last update: {{ selectedTask.lastUpdate }}</div>
        <div v-if="selectedTask.erroredAt">Errored at: {{ selectedTask.erroredAt }}</div>
        <div v-if="selectedTask.completedAt">Completed at: {{ selectedTask.completedAt }}</div>

        <div>Task ID: {{ selectedTask.id }}</div>

        <div class="mb-1">
          Task state: 
          <span class="badge bg-success" v-if="selectedTask.state == 'COMPLETED'">{{ selectedTask.state }}</span>
          <span class="badge bg-info" v-else-if="selectedTask.state == 'PENDING'">{{ selectedTask.state }}</span>
          <span class="badge bg-secondary" v-else>{{ selectedTask.state }}</span>
        </div>

        <div v-if="selectedTask.state == 'COMPLETED'">
          <div v-if="selectedTask.taskType == 'demo_creation_v1'">
            <video controls muted width="500px" :src="selectedTask.demoUrl"></video>

            <DemoCreationMetrics v-if="selectedTask.metrics" />
            <div v-else>No metrics found</div>
            <h1></h1>

          </div>
        </div>

        <div v-if="selectedTask.state == 'ERROR'">
          <div>
            Error:

            <ssh-pre language="js" label="Error" copy-button class="code">{{selectedTask.error}}</ssh-pre>
          </div>
        </div>
      </div>
    </main>
    <main class="h-100 w-100" v-else>
      <div v-if="errorLoading" class="h-100 w-100 d-flex justify-content-center">
        <div class="text-center my-auto"> Unable to load task: {{ errorLoading }}</div>
      </div>
      <div v-else class="container">
        Loading
      </div>
    </main>
</template>
<script>
// In your VueJS component.
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'

import { mapGetters } from 'vuex';
import DemoCreationMetrics from "@/components/Tasks/DemoCreation/DemoCreationMetrics.vue";
import { upsertTask } from '../api';

export default {
  mixins: {  },
  components: {
    DemoCreationMetrics, SshPre
  },
    data() {
        return {
          lastUpdate: null,
          errorLoading: null,
        }
    },
  async mounted() {
    console.log("Task mounted");
    try { 
      console.log(`Requesting task ID: ${this.$route.params.id}`)
      this.updateTask();
    } catch (e) {
      this.errorLoading = e.message;
    }

    setInterval(this.updateTask, 5000);
  },
  async destroyed() {
    console.log("Task destroyed");
  },
  computed: {
      ...mapGetters(['selectedTask']),
  },
  watch: {
  },
  methods: {
    async updateTask() {
      await this.$store.dispatch('getTask', this.$route.params.id);
      this.$store.dispatch('selectTask', this.$route.params.id);
      this.lastUpdate = new Date();
    },
    async restartTask() {
      const task = this.selectedTask;
      task.state = "PENDING";
      const { data: newTask } = await upsertTask(task);
      context.commit("UPDATE_TASK", newTask);
    }
  }
}
</script>

<style>

.code {
  background-color: #dddddd;
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid black;
  
}

</style>